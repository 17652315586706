export default {
  name: "DetailDeposit",
  data() {
    return {
      identity: {
        depositId: "",
      },
      property: {
        animation: {
          editDeposit: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        zakatChartOfAccountId: "",
        pajakChartOfAccountId: "",
        bpjsChartOfAccountId: "",
        bpjs: 0,
        depositId: null,
        statusId: "",
        productCategoryId: "",
        depositCode: "",
        depositName: "",
        currencyId: "",
        isMudharabahMuqayyadah: false,
        depositMinimum: 0,
        depositMaximum: 0,
        nominalNotTaxable: 0,
        tax: 0,
        zakat: 0,
        depositBreakPenalty: "",
        zakatAccountNumber: "",
        infakAccountNumber: "",
        depositTimeTypeId: "",
        depositProfitShareTypeId: "",
        depositAkadTypeId: "",
        chartOfAccountId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        mataUang: [],
        jenisWaktu: [],
        nisbah: [],
        pembukuanBagiHasil: [],
        kodeProduk: [],
        parameterCoaList: [],
      },
    };
  },
  methods: {
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("DEPOSIT_ID_DETAIL");
      this.identity.depositId = getTokenFromSession;
      this.$store.dispatch("SET_IDENTIFIER_DEPOSIT_ID", getTokenFromSession);
    },
    async getDepositById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListDeposit();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "deposit/" + this.identity.depositId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.depositCode = resp.data.data.depositCode;
            this.dataForm.depositName = resp.data.data.depositName;
            this.dataForm.currencyId = resp.data.data.mcurencyResponseDto
              ? resp.data.data.mcurencyResponseDto.currencyId
              : "";
            this.dataForm.isMudharabahMuqayyadah =
              resp.data.data.isMudharabahMuqayyadah;
            this.dataForm.depositMinimum = resp.data.data.depositMinimum;
            this.dataForm.depositMaximum = resp.data.data.depositMaximum;
            this.dataForm.nominalNotTaxable = resp.data.data.nominalNotTaxable;
            this.dataForm.tax = resp.data.data.tax;
            this.dataForm.zakat = resp.data.data.zakat;
            this.dataForm.depositBreakPenalty =
              resp.data.data.depositBreakPenalty;
            this.dataForm.zakatAccountNumber =
              resp.data.data.zakatAccountNumber;
            this.dataForm.infakAccountNumber =
              resp.data.data.infakAccountNumber;
            this.dataForm.statusId = resp.data.data.rstatus
              ? resp.data.data.rstatus.statusId
              : "";
            this.dataForm.depositTimeTypeId = resp.data.data.rdepositTimeType
              ? resp.data.data.rdepositTimeType.depositTimeTypeId
              : "";
            this.dataForm.depositProfitShareTypeId = resp.data.data
              .rdepositProfitShareType
              ? resp.data.data.rdepositProfitShareType.profitShareTypeId
              : "";
            this.dataForm.depositAkadTypeId = resp.data.data.rdepositAkadType
              ? resp.data.data.rdepositAkadType.depositAkadTypeId
              : "";
            this.dataForm.chartOfAccountId = resp.data.data.mchartOfAccountDto
              ? resp.data.data.mchartOfAccountDto.chartOfAccountId
              : "";
            this.dataForm.pajakChartOfAccountId =
              resp.data.data.pajakChartOfAccountId;
            this.dataForm.zakatChartOfAccountId =
              resp.data.data.zakatChartOfAccountId;
              this.dataForm.bpjs =
              resp.data.data.bpjs;
            this.dataForm.bpjsChartOfAccountId =
              resp.data.data.bpjsChartOfAccountId;
            this.dataForm.createdByUserCode = resp.data.data.createdByUserCode;
            this.dataForm.createdByUserName = resp.data.data.createdByUserName;
            this.dataForm.createdDate = resp.data.data.createdDate;
            this.dataForm.updatedDate = resp.data.data.updatedDate;
            this.dataForm.updatedByUserCode = resp.data.data.updatedByUserCode;
            this.dataForm.updatedByUserName = resp.data.data.updatedByUserName;
            this.dataForm.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.authorizedByUserName =
              resp.data.data.authorizedByUserName;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListDeposit(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListDeposit(),
          });
        }
      }
    },
    routeToPageListDeposit() {
      this.$router.push("list");
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.currencyId;
            const text = i.currencyName;
            this.options.mataUang.push({ value, text });
          });
        }
      } catch (error) {}
    },
    async getReferenceDepositTimeType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-time-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.depositTimeTypeId;
            const text = i.depositTimeTypeName;
            this.options.jenisWaktu.push({ value, text });
          });
        }
      } catch (error) {}
    },
    async getReferenceDepositAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.depositAkadTypeId;
            const text = i.depositAkadTypeName;
            this.options.nisbah.push({ value, text });
          });
        }
      } catch (error) {}
    },
    async getReferenceDepositProfitShareTypeData() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-profit-share-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.profitShareTypeId;
            const text = i.profitShareTypeName;
            this.options.pembukuanBagiHasil.push({ value, text });
          });
        }
      } catch (error) {}
    },
    getReferenceProductCategoryId() {
      this.$store.dispatch("GET_REFERENCE_PRODUCT_CATEGORY").then((resp) => {
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            if (
              i.productCategoryId ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_PRODUCT_CATEGORY_DEPOSITO
            ) {
              this.dataForm.productCategoryId = i.productCategoryCode;
            }
          });
        }
      });
    },
    async editDeposit() {
      const payload = {
        productCategoryId: this.dataForm.productCategoryId,
        depositCode: this.dataForm.depositCode,
        depositName: this.dataForm.depositName,
        currencyId: this.dataForm.currencyId,
        isMudharabahMuqayyadah: this.dataForm.isMudharabahMuqayyadah,
        depositMinimum: this.dataForm.depositMinimum,
        depositMaximum: this.dataForm.depositMaximum,
        nominalNotTaxable: this.dataForm.nominalNotTaxable,
        tax: this.dataForm.tax,
        zakat: this.dataForm.zakat,
        depositBreakPenalty: this.dataForm.depositBreakPenalty,
        zakatAccountNumber: this.dataForm.zakatAccountNumber,
        infakAccountNumber: this.dataForm.infakAccountNumber,
        depositTimeTypeId: this.dataForm.depositTimeTypeId,
        depositProfitShareTypeId: this.dataForm.depositProfitShareTypeId,
        depositAkadTypeId: this.dataForm.depositAkadTypeId,
        statusId: this.dataForm.statusId,
        chartOfAccountId: this.dataForm.chartOfAccountId,
        depositId: this.identity.depositId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Produk Deposito ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editDeposit.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl: "deposit/" + this.identity.depositId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editDeposit.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    getParameterCoa() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "chart-of-account",
          params: {
            description: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((index) => {
              const text = `${index.chartOfAccountCode} - ${index.description}`;
              const value = index.chartOfAccountId;
              this.options.parameterCoaList.push({ text, value });
            });
          }
        });
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getDepositById();
    this.getReferenceCurrency();
    this.getReferenceDepositTimeType();
    this.getReferenceDepositAkadType();
    this.getReferenceDepositProfitShareTypeData();
    this.getReferenceProductCategoryId();
    this.getParameterCoa();
  },
};
