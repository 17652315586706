import Mixins from "../../Mixins.js";

export default {
  name: "DetailDepositNisbahChartOfAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        depositId: "",
      },
      property: {
        modal: {
          showModalEditDepositNisbahChartOfAccount: false,
        },
        listElement: {
          depositNisbahChartOfAccount: {
            rows: 0,
            currentPage: 1,
            perPage: 5,
            downloading: false,
            message: "",
          },
        },
        animation: {
          addDepositNisbahChartOfAccount: {
            isLoading: false,
          },
          editDepositNisbahChartOfAccount: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        add: {
          bonusChartOfAccountId: "",
          depositId: "",
          depositTimeTypeName: "",
          timePeriode: 0,
          nisbah: 0,
          depositName: "",
          nisbahOld: 0,
          isActive: false,
          productCategoryName: "",
          depositNisbahId: "",
          depositChartOfAccountId: "",
          profitShareChartOfAccountId: "",
          nisbahSpecial: 0,
        },
        edit: {
          bonusChartOfAccountId: "",
          depositId: "",
          depositTimeTypeName: "",
          timePeriode: 0,
          nisbah: 0,
          depositName: "",
          nisbahOld: 0,
          isActive: false,
          productCategoryName: "",
          depositNisbahId: "",
          depositChartOfAccountId: "",
          profitShareChartOfAccountId: "",
          nisbahSpecial: 0,
        },
      },
      options: {
        profitShareChartOfAccount: [],
      },
      table: {
        data: {
          depositNisbahChartOfAccount: [],
        },
      },
    };
  },
  methods: {
    async getDepositById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "deposit/" + this.identity.depositId,
          });

          if (resp.data.code === "SUCCESS") {
            this.dataForm.add.depositChartOfAccountId = resp.data.data
              .mchartOfAccountDto
              ? resp.data.data.mchartOfAccountDto.chartOfAccountId
              : "";
            this.dataForm.add.depositName = resp.data.data.depositName;
            this.dataForm.add.depositTimeTypeName = resp.data.data
              .rdepositTimeType
              ? resp.data.data.rdepositTimeType.depositTimeTypeName
              : "";
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    getProductCategoryNameDepositNisbahChartOfAccount() {
      this.$store.dispatch("GET_REFERENCE_PRODUCT_CATEGORY").then((resp) => {
        resp.data.data.map((i) => {
          if (
            i.productCategoryId ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_PRODUCT_CATEGORY_DEPOSITO
          ) {
            this.dataForm.add.productCategoryName = i.productCategoryName;
          }
        });
      });
    },
    async getDepositNisbahChartOfAccount() {
      this.table.data.depositNisbahChartOfAccount = [];
      this.property.listElement.depositNisbahChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-nisbah-chart-of-account",
          payload: {
            depositId: !this.identity.depositId ? "" : this.identity.depositId,
            page: 0,
            size: this.property.listElement.depositNisbahChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositNisbahChartOfAccount =
              resp.data.data.content;
            this.property.listElement.depositNisbahChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.property.listElement.depositNisbahChartOfAccount.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositNisbahChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositNisbahChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    handleErrorGetDepositNisbahChartOfAccount(error) {
      console.log(error.response);
      this.table.data.depositNisbahChartOfAccount = [];
      this.property.listElement.depositNisbahChartOfAccount.rows = 0;
      this.property.listElement.depositNisbahChartOfAccount.currentPage = 1;
      this.property.listElement.depositNisbahChartOfAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationDepositNisbahChartOfAccount(event) {
      this.table.data.depositNisbahChartOfAccount = [];
      this.property.listElement.depositNisbahChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-nisbah-chart-of-account",
          payload: {
            depositId: !this.identity.depositId ? "" : this.identity.depositId,
            page: event - 1,
            size: this.property.listElement.depositNisbahChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositNisbahChartOfAccount =
              resp.data.data.content;
            this.property.listElement.depositNisbahChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataDepositNisbahChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositNisbahChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositNisbahChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataDepositNisbahChartOfAccount(resp) {
      this.table.data.depositNisbahChartOfAccount = [];
      this.property.listElement.depositNisbahChartOfAccount.rows = 0;
      this.property.listElement.depositNisbahChartOfAccount.message =
        resp.data.message;
    },
    async addDepositNisbahChartOfAccount() {
      const payload = {
        timePeriode: this.dataForm.add.timePeriode,
        nisbah: this.dataForm.add.nisbah,
        nisbahOld: this.dataForm.add.nisbahOld,
        depositId: this.identity.depositId,
        isActive: this.dataForm.add.isActive,
        depositChartOfAccountId: this.dataForm.add.depositChartOfAccountId,
        profitShareChartOfAccountId: this.dataForm.add
          .profitShareChartOfAccountId,
        nisbahSpecial: this.dataForm.add.nisbahSpecial,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Deposit Nisbah ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addDepositNisbahChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "deposit-nisbah-chart-of-account",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.resetFormDepositNisbahChartOfAccount("ADD");
                    this.getDepositNisbahChartOfAccount();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addDepositNisbahChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    resetFormDepositNisbahChartOfAccount(type) {
      switch (type) {
        case "ADD":
          this.dataForm.add.timePeriode = 0;
          this.dataForm.add.nisbah = 0;
          this.dataForm.add.nisbahOld = 0;
          this.dataForm.add.isActive = false;
          this.dataForm.add.depositNisbahId = "";
          this.dataForm.add.depositId = "";
          this.dataForm.add.profitShareChartOfAccountId = "";
          this.dataForm.add.bonusChartOfAccountId = "";
          this.dataForm.add.nisbahSpecial = 0;
          break;
        case "EDIT":
          this.dataForm.edit.timePeriode = 0;
          this.dataForm.edit.nisbah = 0;
          this.dataForm.edit.nisbahOld = 0;
          this.dataForm.edit.isActive = false;
          this.dataForm.edit.depositNisbahId = "";
          this.dataForm.edit.depositId = "";
          this.dataForm.edit.depositName = "";
          this.dataForm.edit.depositTimeTypeName = "";
          this.dataForm.edit.profitShareChartOfAccountId = "";
          this.dataForm.edit.depositChartOfAccountId = "";
          this.dataForm.edit.bonusChartOfAccountId = "";
          this.dataForm.edit.nisbahSpecial = 0;
          break;

        default:
          return;
          break;
      }
    },
    async getDepositNisbahChartOfAccountById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "deposit-nisbah-chart-of-account/" + props.row.depositNisbahId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.productCategoryName = resp.data.data
              .mdepositResponseDto
              ? resp.data.data.mdepositResponseDto.mproductCategory
                ? resp.data.data.mdepositResponseDto.mproductCategory
                    .productCategoryName
                : ""
              : "";
            this.dataForm.edit.depositTimeTypeName = resp.data.data
              .mdepositResponseDto
              ? resp.data.data.mdepositResponseDto.rdepositTimeType
                ? resp.data.data.mdepositResponseDto.rdepositTimeType
                    .depositTimeTypeName
                : ""
              : "";
            this.dataForm.edit.depositName = resp.data.data.mdepositResponseDto
              ? resp.data.data.mdepositResponseDto.depositName
              : "";
            this.dataForm.edit.depositId = resp.data.data.mdepositResponseDto
              ? resp.data.data.mdepositResponseDto.depositId
              : "";
            this.dataForm.edit.timePeriode = resp.data.data.timePeriode;
            this.dataForm.edit.nisbah = resp.data.data.nisbah;
            this.dataForm.edit.nisbahOld = resp.data.data.nisbahOld;
            this.dataForm.edit.isActive = resp.data.data.isActive;
            this.dataForm.edit.depositNisbahId = resp.data.data.depositNisbahId;
            this.dataForm.edit.depositChartOfAccountId = resp.data.data
              .mchartOfAccount
              ? resp.data.data.mchartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.profitShareChartOfAccountId = resp.data.data
              .profitShareChartOfAccount
              ? resp.data.data.profitShareChartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.nisbahSpecial = resp.data.data.nisbahSpecial;
            this.dataForm.edit.bonusChartOfAccountId =
              resp.data.data.bonusChartOfAccountId;
            this.property.modal.showModalEditDepositNisbahChartOfAccount = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    closeModalEditDepositNisbahChartOfAccount() {
      this.resetFormDepositNisbahChartOfAccount("EDIT");
      this.property.modal.showModalEditDepositNisbahChartOfAccount = false;
    },
    async editDepositNisbahChartOfAccount() {
      const payload = {
        timePeriode: this.dataForm.edit.timePeriode,
        nisbah: this.dataForm.edit.nisbah,
        nisbahOld: this.dataForm.edit.nisbahOld,
        depositId: this.identity.depositId,
        isActive: this.dataForm.edit.isActive,
        depositChartOfAccountId: this.dataForm.edit.depositChartOfAccountId,
        profitShareChartOfAccountId: this.dataForm.edit
          .profitShareChartOfAccountId,
        nisbahSpecial: this.dataForm.edit.nisbahSpecial,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Deposit Nisbah ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editDepositNisbahChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-nisbah-chart-of-account/" +
                  this.dataForm.edit.depositNisbahId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.showModalEditDepositNisbahChartOfAccount = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormDepositNisbahChartOfAccount("EDIT");
                      this.getDepositNisbahChartOfAccount();
                    }, 500);
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editDepositNisbahChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async deleteDepositNisbahChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Ingin Menghapus Data ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-nisbah-chart-of-account/" +
                  props.row.depositNisbahId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getDepositNisbahChartOfAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    getIdentityFromMixin() {
      this.identity.depositId = this.mixin_data_identifier_deposit_id;
    },
    getReferenceChartOfAccount() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            description: "",
            page: 0,
          },
          url: "chart-of-account",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.profitShareChartOfAccount.push({
              value,
              text,
              description,
            });
          });
        });
    },
  },
  mounted() {
    this.getIdentityFromMixin();
    this.getDepositById();
    this.getProductCategoryNameDepositNisbahChartOfAccount();
    this.getDepositNisbahChartOfAccount();
    this.getReferenceChartOfAccount();
  },
};
